import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useWebSettingContext } from '../../context/WebSetting';
import { Server } from '../../Server';
import axios from 'axios';
import { toast } from 'react-toastify';
import Consultation from '../Consultation/Main';

const Footer = () => {
    const { phone_no, email, address, copyright, our_services, footer_info_text, footer_logo, social_icons } = useWebSettingContext();
    const [subemail, setsubEmail] = useState();
    const today = new Date()
    const year = today.getFullYear()
    
    const handleSubscription = (event) => {
        event.preventDefault()
        axios.get('/sanctum/csrf-cookie').then((response) => {
            axios
              .post('/api/newsletter-subscribe', {'email':subemail})
              .then((res) => {
                if(res.status===200 && res.data.success_status==='existing'){
                    toast.info("This email is already subscribed")
                }
                else if(res.status===200 && res.data.success===true){
                    toast.success("Thank You for subscribing us!");
                    toast.success("You will get all updates on regular basis!")
                }
                else{
                    toast.error('Subscription is unsuccessfull!');
                    toast.error('Please try again!')
                }
                
              })
              .catch((error) => {
                toast.error('Some error occurred');
                toast.error('Please try again!')
              })
        })
    }

    return (
        <>
            <div className={`footer-main section-padding--bottom`}>
                <div className="container ">
                    <div className="row gutter-y-30 pt-5">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo">
                                    {
                                        footer_logo && <img src={`${Server}` + footer_logo} width="210" alt="Logo" />
                                    }
                                </Link>
                                <p className="footer-widget__text" style={{ marginTop: '16px' }}>{footer_info_text}</p>
                                <ul className="footer-widget__social">
                                {social_icons && social_icons.map((item, index) => (
                                    <li key={index}><a href={item.link}>
                                    <div dangerouslySetInnerHTML={{__html: item.title}} />
                                    </a></li>
                                ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">Ser<span className='text-black'>vices</span></h3>
                                <ul className="footer-widget__links">
                                    {our_services && our_services.map((item, index) => (
                                        <li key={index}>
                                            <Link to={item.link}>{item.title}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--contact">
                                <h3 className="footer-widget__title">Con<span className='text-black'>tact</span></h3>
                                <ul className="footer-widget__contact">
                                    {phone_no &&
                                        <li>
                                            <i className="fa fa-phone"></i>
                                            <a href={`tel:${phone_no}`}>{phone_no}</a>
                                        </li>
                                    }
                                    {email &&
                                        <li>
                                            <i className="fa fa-envelope"></i>
                                            <a href={`mailto:${email}`}>{email}</a>
                                        </li>
                                    }
                                    {address &&
                                        <li>
                                            <i className="fa fa-map-marker-alt"></i>
                                            {address}
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--newsletter">
                                <h3 className="footer-widget__title">Sub<span className='text-black'>scribe</span></h3>
                                <p className="footer-widget__text">Subscribe to stay in touch with Us</p>
                                <form onSubmit={handleSubscription} className="footer-widget__newsletter mc-form"
                                    data-url="ADD_YOUR_MAILCHIMP_FORM_URL_HERE">
                                    <input type="email" onChange={(e) => setsubEmail(e.target.value)} required name="sub_email" placeholder="Enter Your Email" />
                                    <button type='submit' className="thm-btn footer-widget__newsletter__btn"><span>Subscribe</span></button>
                                </form>
                                <div className="mc-form__response"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p>&copy; {copyright} {year}</p>
                </div>
            </div>

            <Consultation />
        </>
    )
}

export default Footer