import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom'
import { format } from 'date-fns';
import { Server } from '../../../Server';

const BlogList = ({ filterData }) => {
    const [currentItems, setCurrentItems] = useState([]);
    useEffect(() => {
        //current paginate blgos
        setCurrentItems(filterData);
    }, [filterData]);
    return (
        <>
            {
                filterData && filterData.length === 0 && <h3>No Blog Found!</h3>
            }
            {currentItems && currentItems.map((item, i) => (
                <div key={i} className="col-12 mt-3 wow fadeInUp" data-wow-duration="1500ms"
                    data-wow-delay="000ms">
                    <div className="blog-card-one">
                        <div className="blog-card-one__image">
                            <img src={Server+item.featured_image} alt={item.featured_image_alt} />
                            <Link to={"/blog/" + item.slug}></Link>
                        </div>
                        <div className="blog-card-one__content">
                            <div className="blog-card-one__meta">
                                <div className="blog-card-one__date">
                                    <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                    {format(new Date(item.created_at), 'do  MMMM yyyy')}
                                </div>
                            </div>
                            <h3 className="blog-card-one__title">
                                <Link to={"/blog/" + item.slug}>{item.title}</Link>
                            </h3>
                            <p className='fw-normal blog-intro'>{item.featured_desc}</p>
                            <Link to={"/blog/" + item.slug} className="blog-card-one__more">
                                Read More
                                <i className="fa fa-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BlogList