import React from 'react'
import { Link } from 'react-router-dom'
import { Server } from '../../../Server'
import format from 'date-fns/format'
const BlogSidebar = ({ blogCategories, trending, categoryFilter, blogCount, blogSearch, clearBlogSearc }) => {
    return (
        <div className="sidebar mt-3">
            <div className="sidebar__item sidebar__item--search">
                <form action="#" className="sidebar__search">
                    <label for="search" className="sr-only">search here</label>
                    <input type="text" placeholder="Search Here" required id="blog_search" onChange={(e) => e.target.value} />
                    <button type="submit" aria-label="search submit" onClick={(e) => blogSearch(e)} className="thm-btn">
                        <span><i className="icon-magnifying-glass"></i></span>
                    </button>
                    <Link to="#" className="float-end text-danger" onClick={() => clearBlogSearc()}><i className="fas fa-times"></i> Clear</Link>
                </form>
            </div>
            <div className="sidebar__item sidebar__item--category">
                <h3 className="sidebar__title">Categories</h3>

                <ul className="sidebar__category">
                    {
                        blogCategories &&
                        <li className="cat-item cat-item-3">
                            <Link to="#" onClick={() => categoryFilter('All')}>
                                All
                            </Link>
                            {blogCount}
                        </li>
                    }
                    {
                        blogCategories &&
                        Object.entries(blogCategories).map(([key, value], index) => (
                            <>
                                <li className="cat-item cat-item-3" key={index}>
                                    <Link to="#" onClick={() => categoryFilter(key)}>
                                        {key}
                                    </Link>
                                    {value}
                                </li>
                            </>
                        ))
                    }
                </ul>
            </div>
            <div className="sidebar__item sidebar__item--posts">
                <h3 className="sidebar__title">Latest Posts</h3>
                <ul className="sidebar__posts">
                    {
                        trending.length > 0 && trending.map((item, key) => (
                            <li key={key}>
                                <div className="sidebar__posts__image">
                                    <img src={Server + item.featured_image} alt={item.featured_image_alt} className='img-fluid' style={{ height: '70px', width: '73px' }} />
                                </div>
                                <div className="sidebar__posts__content">
                                    <h3 className="sidebar__posts__title"><Link to={"/blog/" + item.slug}>{item.title}</Link></h3>
                                    <p>
                                        <i className="fa fa-calendar-alt me-1" aria-hidden="true"></i>
                                        {format(new Date(item.created_at), 'do  MMMM yyyy')}</p>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default BlogSidebar