import React, { useEffect, useState } from 'react'
import Breadcumb from '../Breadcumb/Main'
import { useFormik } from 'formik'
import { inquirySchema } from '../../Schemas/index'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useWebSettingContext } from '../../context/WebSetting';
import { Helmet } from 'react-helmet'
import { Server } from '../../Server'
import Spinner from '../Spinner/Main'
const Contact = ({setErrorvisible}) => {
    const { phone_no, email, address, social_icons } = useWebSettingContext();
    const [contactPageDetail, setcontactPageDetail] = useState('')
    const [breadCrumbData, setbreadCrumbData] = useState();
    const [pageMetas, setpageMetas] = useState();
    const [spinner, setSpinner] = useState(true);

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: { email: '', customer_name: '', customer_mobile: '', email_subject: '', email_message: '' },
        validationSchema: inquirySchema,
        onSubmit: (values, actions) => {
            axios.get('/sanctum/csrf-cookie').then((response) => {
                axios
                    .post('/api/customer-inquiry', values)
                    .then((res) => {
                        toast.success("Your query is submitted successfully!");
                        actions.resetForm();
                    })
                    .catch((error) => {
                        toast.error('Some error occurred. Please try again!');
                    })
            })
        },
    })

    const getContactPageData = async () => {
        await axios.get('/sanctum/csrf-cookie').then((response) => {
            axios
                .get('/api/contactus-page-details')
                .then((res) => {
                    setcontactPageDetail(res.data.contact_us_details)
                    setbreadCrumbData(res.data.breadcrumb_banner)
                    setpageMetas(res.data.page_metas)
                    setSpinner(false)
                })
                .catch((error) => {
                    setSpinner(false)
                    toast.error('Some error occurred!')
                    setErrorvisible(true)
                })
              
        })
    }

    useEffect(() => {
        setErrorvisible(false)
        getContactPageData()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'SoftTecHub'}</title>
            </Helmet>
            {spinner && <Spinner /> }
            {contactPageDetail &&
                <div className='wow fadeIn animated'>
                    <Breadcumb BreadCumbData={breadCrumbData} Title={contactPageDetail.title} Breadcumb={contactPageDetail.title} />

                    <section className="contact-one section-padding--top section-padding--bottom wow fadeIn animated">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <form onSubmit={handleSubmit} className="contact-one__form contact-form-validated">
                                        <div className="section-title">
                                            <p className="section-title__text">Send a Message</p>
                                            <h2 className="section-title__title">Tell us about your query</h2>
                                        </div>
                                        <div className="row ">
                                            <div className="col-lg-6 col-md-12">
                                                <input type="text" placeholder="Your name" name="customer_name"
                                                    value={values.customer_name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errors.customer_name && touched.customer_name && (
                                                    <p className="text-danger">{errors.customer_name}</p>
                                                )}
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <input type="email" placeholder="Email address" name="email"
                                                    value={values.email}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && touched.email && (
                                                    <p className="text-danger">{errors.email}</p>
                                                )}
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <input type="text" placeholder="Phone number" name="customer_mobile"
                                                    value={values.customer_mobile}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errors.customer_mobile && touched.customer_mobile && (
                                                    <p className="text-danger">{errors.customer_mobile}</p>
                                                )}
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <input type="text" placeholder="Subject" name="email_subject"
                                                    value={values.email_subject}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {errors.email_subject && touched.email_subject && (
                                                    <p className="text-danger">{errors.email_subject}</p>
                                                )}
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <textarea name="email_message" placeholder="Write comment"
                                                    value={values.email_message}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} >
                                                </textarea>
                                                {errors.email_message && touched.email_message && (
                                                    <p className="text-danger">{errors.email_message}</p>
                                                )}
                                            </div>
                                            <div className="col-md-12">
                                                <button className="thm-btn contact-one__btn" type="submit"><span>send a
                                                    message</span></button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="result"></div>
                                </div>
                                {contactPageDetail.pagedetail && contactPageDetail.pagedetail[0].type === 'contact_map_section' &&
                                    <div className="col-lg-4">
                                        <div className="contact-one__info wow fadeInRight" data-wow-duration="1500ms" style={{ backgroundImage: `url(${Server + '' + contactPageDetail.pagedetail[0].image})` }}>
                                            <div className="contact-one__item">
                                                <h3 className="contact-one__item__title">Address</h3>
                                                <p className="contact-one__item__text_address">{address}</p>
                                            </div>
                                            <div className="contact-one__item">
                                                <h3 className="contact-one__item__title">Phone</h3>
                                                <p className="contact-one__item__text">
                                                    Mobile: <a href={`tel:${phone_no}`}>{phone_no}</a></p>
                                            </div>
                                            <div className="contact-one__item">
                                                <h3 className="contact-one__item__title">Email</h3>
                                                <p className="contact-one__item__text">
                                                    <a href={`mailto:${email}`}>{email}</a></p>

                                            </div>
                                            <div className="contact-one__item">
                                                <ul className="contact-one__social">
                                                    {social_icons && social_icons.map((item, index) => (
                                                        <li key={index}><a href={item.link}>
                                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                        </a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>

                    {contactPageDetail.pagedetail && contactPageDetail.pagedetail[0].type === 'contact_map_section' &&
                        <section className="google-map google-map--contact">
                            <iframe
                                src={contactPageDetail.pagedetail[0].content}
                                className="google-map__one" title='gmap' allowFullScreen></iframe>
                        </section>
                    }
                </div>
            }
        </>
    )
}

export default Contact