import React from "react";
import { Server } from "../../Server";
const Modal = ({
    clickedImg,
    setClickedImg,
    handelRotationRight,
    handelRotationLeft
}) => {
    const handleClick = (e) => {
        if (e.target.classList.contains("dismiss")) {
            setClickedImg(null);
        }
    };

    return (
        <>
            <div className="overlay dismiss" onClick={handleClick}>
                <img src={Server + clickedImg} alt={clickedImg} className="img-fluid" />
                <span className="dismiss" onClick={handleClick}>
                    X
                </span>
                <div onClick={handelRotationLeft} className="overlay-arrows_left bg-red">
                    <div>
                        <i className="fas fa-arrow-left fs-4 ms-2 mt-2 text-white"></i>
                    </div>
                </div>
                <div onClick={handelRotationRight} className="overlay-arrows_right bg-red">
                    <div>
                        <i className="fas fa-arrow-right fs-4 ms-2 mt-2 text-white"></i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
