import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import WOW from 'wowjs'
import { useWebSettingContext } from '../../context/WebSetting';
import { Server } from '../../Server';

const Navbar = () => {
    const { logo, email, address, navitems, phone_no, social_icons, footer_logo } = useWebSettingContext();

    const location = useLocation()
    const path = location.pathname
    const [sticky, setSticky] = useState(false)
    // const [search, setSearch] = useState(false)
    const [mobile, setmobile] = useState(false)
    const [home, setHome] = useState(null)
    const mynavItem = (item) => {
        setHome(home === item ? null : item);
    }
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
    }, [path])

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    function splitToChunks(array, parts) {
        let result = [];
        for (let i = parts; i > 0; i--) {
            result.push(array.splice(0, Math.ceil(array.length / i)));
        }
        return result;
    }

    return (
        <>
            <div className="topbar">
                <div className="container-fluid">
                    <ul className="topbar__social ms-0">
                        {social_icons && social_icons.map((item, index) => (
                            <li key={index}><a href={item.link}>
                                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                            </a></li>
                        ))}
                    </ul>
                    <ul className="topbar__info" >
                        {email &&
                            <li>
                                <i className="fa fa-envelope"></i>
                                <a href={`mailto:${email}`}>{email}</a>
                            </li>
                        }
                        {address &&
                            <li>
                                <i className="fa fa-map-marker"></i>
                                {address}
                            </li>
                        }

                    </ul>
                </div>
            </div>
            <nav className={`main-menu sticky-header ${sticky && "sticky-header--cloned sticky-fixed"}`}>
                <div className="container-fluid">
                    <div className="main-menu__logo ">
                        <Link to="/">
                            {
                                logo && <img src={`${Server}` + logo} width="210" alt="logo" />
                            }
                        </Link>
                    </div>

                    <div className='main-menu__right'>
                    <Link to="#" className="mobile-nav__toggler" onClick={() => setmobile(true)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>
                    <ul className="main-menu__list">
                        {navitems.length > 0 && navitems.map((item, index) => (
                            <li key={index} className={`menu-item-has-children`}>
                                <Link to={item.link}>{item.title}
                                    {item.nav.length > 0 && <i className="fa-solid fa-angle-down ms-2 fs-6"></i>}
                                </Link>
                                {item.nav.length > 0 &&
                                    <ul>
                                        {item.nav.map((item2, index2) => (
                                            <li key={index2} className={`menu-item-has-children`}>
                                                <Link to={item2.link}>{item2.title}
                                                    {item2.nav.length > 0 &&
                                                        <i className="fa-solid fa-angles-right ms-3 fs-6"></i>
                                                    }
                                                </Link>
                                                {item2.nav.length > 0 &&
                                                    <ul style={{ minWidth: '400px' }}>
                                                        {splitToChunks([...item2.nav], 2).map((item3, index3) => (
                                                            <>
                                                                {item3.map((item4, index4) => (
                                                                    <li key={index4} style={{ display: 'inline-block', width: '50%' }}><Link to={item4.link}>{item4.title}</Link></li>
                                                                ))}
                                                            </>
                                                        ))}

                                                    </ul>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </li>
                        ))}
                    </ul>
                    </div>
                    
                    {/* <div className="main-menu__right">
                        <Link to="#" className="mobile-nav__toggler" onClick={() => setmobile(true)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>
                        {phone_no &&
                            <a href={`tel:${phone_no}`} className="main-menu__cta">
                                <i className="fa fa-phone-alt"></i>
                                <span className="main-menu__cta__text">
                                    <b>{phone_no}</b>
                                    Call Anytime
                                </span>
                            </a>
                        }
                    </div> */}

                </div>
            </nav>
            <div className={`mobile-nav__wrapper ${mobile && "expanded"}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                <div className="mobile-nav__content">
                    <Link to="#" onClick={() => setmobile(false)} className="mobile-nav__close mobile-nav__toggler">
                        <span></span>
                        <span></span>
                    </Link>

                    <div className="logo-box">
                        <Link to="/" aria-label="logo image">
                            {
                                logo && <img src={`${Server}` + footer_logo} width="210" height="60" alt="logo" />
                            }
                        </Link>
                    </div>
                    <div className="mobile-nav__container">
                        <ul className="mobile-menu__list">
                            {
                                navitems.length > 0 &&
                                navitems.map((item, key1) => (
                                    <li key={key1} className={item.nav.length > 0 ? `menu-item-has-children` : ''}>
                                        <Link to={item.link}>{item.title}
                                            {
                                                item.nav.length > 0 && <button aria-label="dropdown toggler" onClick={() => mynavItem(item.title)}><i className="fa fa-angle-down"></i></button>
                                            }
                                        </Link>
                                        {
                                            item.nav.length > 0 && home === item.title && <ul style={{ display: "block" }}>
                                                {
                                                    item.nav.map((item2, key2) => (
                                                        <li key={key2}><Link to={item2.link}>{item2.title}</Link>
                                                            {
                                                                item2.nav.length > 0 &&
                                                                item2.nav.map((item3, key3) => (
                                                                    <li key={key3}
                                                                        style={{ borderRight: key3 === 0 ? '1px solid black' : '' }}
                                                                    >
                                                                        <Link to={item3.link} className='ms-3 text-danger' >{item3.title}</Link></li>
                                                                ))
                                                            }
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-phone"></i>
                            <a href={`tel:${phone_no}`}>{phone_no}</a>
                        </li>
                        <li>
                            <i className="fa fa-envelope"></i>
                            <a href={`mailto:${email}`}>{email}</a>
                        </li>
                        <li>
                            <i className="fa fa-map-marker-alt"></i>
                            {address}
                        </li>
                    </ul>
                    <ul className="footer-widget__social">
                        {social_icons && social_icons.map((item, index) => (
                            <li key={index}><a href={item.link}>
                                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                            </a></li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Navbar