import React, { useEffect, useState } from 'react'
import Breadcumb from '../Breadcumb/Main'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'
import Spinner from '../Spinner/Main'
import BGImg8 from '../../assets/images/shapes/image (10).png'
import PortfolioList from './PortfolioList'
import { Server } from '../../Server'
import { Link } from 'react-router-dom'

const Main = ({ setErrorvisible }) => {
    const [portfolioList, setPortfolioList] = useState([]);
    const [breadCrumbData, setbreadCrumbData] = useState();
    const [pageMetas, setpageMetas] = useState();
    const [spinner, setSpinner] = useState(false);

    const getPortfolioPage = () => {
        axios
            .get('/api/portfolioList')
            .then((res) => {
                setpageMetas(res.data.page_metas)
                setbreadCrumbData(res.data.breadcrumb_banner)
                setPortfolioList(res.data.portfolio_list)
                setSpinner(false)
            })
            .catch((error) => {
                setSpinner(false)
                toast.error('Some error occurred!')
                setErrorvisible(true)
            })
    }
    useEffect(() => {
        setErrorvisible(false)
        getPortfolioPage()
    }, [])
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'SoftTecHub'}</title>
                <meta name='description' content={pageMetas && pageMetas.meta_description} />
                <meta name='keywords' content={pageMetas && pageMetas.meta_keywords} />
            </Helmet>
            {spinner ? <Spinner /> :

                <>
                    <div className='wow fadeIn animated' style={{ backgroundImage: `url(${BGImg8})` }}></div>
                    <Breadcumb BreadCumbData={breadCrumbData} Title='Portfolio Projects' Breadcumb="PORTFOLIO" />
                    <section className="section-padding--bottom section-padding--top">
                        <div className="container">
                            <div className="row gutter-y-30">
                                {portfolioList && portfolioList.length > 0 &&
                                    portfolioList.map((item, key) => (
                                        <div key={key} className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp" data-wow-duration="1500ms"
                                            data-wow-delay="000ms">
                                            <div className="project-card-one">
                                                <div className="project-card-one__image">
                                                    <img src={Server + item.main_image} alt={item.main_image_alt} className='img-fluid' />
                                                </div>
                                                <div className="project-card-one__content">
                                                    <div className="project-card-one__content__inner">
                                                        <p className="project-card-one__text">{item.category.category_title}</p>
                                                        <h3 className="project-card-one__title"><Link to={"/project-detail/" + item.slug}>{item.title}</Link></h3>
                                                        <Link to={"/project-detail/" + item.slug} className="project-card-one__more">
                                                            <i className="fa fa-angle-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </section>


                </>
            }
        </>
    )
}

export default Main