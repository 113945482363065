import React from 'react'
import { Link } from 'react-router-dom'
import { Server } from '../../../Server'
import format from 'date-fns/format'
const SingleBlog = ({ singleBlog }) => {
    return (
        <>
            <h3 className="blog-card-one__title blog-details__title mt-5 text-center">{singleBlog && singleBlog.title}</h3>

            <div className="blog-details__image">
                <img src={singleBlog && Server + singleBlog.featured_image} alt={singleBlog && singleBlog.featured_image_alt} className='img-fluid' />
            </div>

            <div className=" mb-4">
                <div className='row'>
                    <div className='col-12'>
                        <div className="blog-card-one__date">
                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                            {format(new Date(singleBlog && singleBlog.created_at), 'do  MMMM yyyy')}
                        </div>
                        <Link to="#" className="blog-card-one__category ms-2">{singleBlog && singleBlog.blogcategory.category_title}</Link>
                    </div>
                </div>
            </div>

            <div className="blog-details__content">

                <div className="author-one">
                    <div className="author-one__content">
                        <p className="author-one__text">{singleBlog && singleBlog.featured_desc}</p>
                    </div>
                </div>
                {
                    singleBlog && singleBlog.blogdetail.length > 0 &&
                    singleBlog.blogdetail.map(item => (
                        <div>
                            {item.type === 'number' ?
                                <img src={item.media && Server + item.media.image} alt='' />

                                :
                                <div dangerouslySetInnerHTML={{ __html: item['content'] }} />}
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default SingleBlog