import React, { useEffect, useState } from 'react'
import Breadcumb from '../Breadcumb/Main'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom';
import ServiceSidebar from '../Services/ServiceSidebar/Main'
import { Server } from '../../Server'
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import Spinner from '../Spinner/Main';

const Dynamic = ({ setErrorvisible }) => {
    const navigate = useNavigate();
    const [pageData, setPageData] = useState('');
    const [servicesList, setServicesList] = useState([]);
    const [breadCrumbData, setbreadCrumbData] = useState();
    const [pageMetas, setpageMetas] = useState();
    const [clicked, setClicked] = useState(0);
    let location = useLocation();
    let url = location.pathname.replace('/', "");
    const [spinner, setSpinner] = useState(true);

    const fetchdynamicpage = async () => {
        await axios.get('/api/getdynamicPage/' + url).then((res) => {
            if (res.data.success === true && res.status === 200) {
                setPageData(res.data.data)
                setServicesList(res.data.services_list)
                setbreadCrumbData(res.data.breadcrumb_banner)
                setpageMetas(res.data.page_metas)
                setSpinner(false)
            }
            else {
                setSpinner(false)
                navigate('/page-404')
            }
        })
        .catch((error) => {
            setSpinner(false)
            toast.error('Some error occurred!')
            setErrorvisible(true)
        })
    }
    useEffect(() => {
        setErrorvisible(false)
        fetchdynamicpage()
    }, [url])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'SoftTecHub'}</title>
            </Helmet>
            {spinner && <Spinner /> }
            {pageData &&
                <div className='wow fadeIn animated'>
                    {breadCrumbData &&
                        <Breadcumb BreadCumbData={breadCrumbData && breadCrumbData} Title={pageData && pageData.title} Breadcumb={pageData && pageData.title} />
                    }
                    <section className="wow fadeIn animated section-padding--bottom section-padding--top service-details--page">
                        <div className="container">
                            <div className="row">
                                <div className={pageData.page_type === 'Service' ? "col-lg-8" : "col-12"}>
                                    <div className='row'>
                                        {pageData.pagedetail && pageData.pagedetail.map((item, index) => (
                                            <div key={index} className={`col-lg-` + item.col}>
                                                {item.type === 'number' ? (
                                                    <img src={`${Server}` + item.media.image} className='img-fluid mt-2 mb-2' alt='#content Img' />
                                                ) : (
                                                    <div className='mt-2' dangerouslySetInnerHTML={{ __html: item.content }} />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {pageData && pageData.page_type === 'Service' &&
                                        <div className="row mb-5">
                                            <div className="col-md-12">
                                                <div className="accrodion-grp service-details__accrodion"
                                                    data-grp-name="service-details__accrodion-1">
                                                    {pageData.faqs && pageData.faqs.map((item, index) => (
                                                        <div key={index} className={`accrodion wow fadeInUp ${index === clicked && "active"}`} data-wow-delay="0ms">
                                                            <div className="accrodion-title">
                                                                <h4 onClick={() => setClicked(index)}>{item.question}<span
                                                                    className="accrodion-icon"></span>
                                                                </h4>
                                                            </div>
                                                            {index === clicked &&
                                                                <div className="accrodion-content">
                                                                    <div className="inner">
                                                                        <p>{item.answer}</p>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {pageData && pageData.page_type === 'Service' &&
                                    <div className="col-lg-4 sidebar-column">
                                        <ServiceSidebar services_list={servicesList && servicesList} id={pageData && pageData.id} reviews={pageData && pageData.reviews} />
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            }

        </>
    )
}

export default Dynamic