import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { Server } from '../../Server'

const PortfolioList = ({ portfolioList }) => {
    const projectOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: true,
        margin: 0,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            },
            1200: {
                margin: 30,
                items: 4
            }
        }
    }

    return (
        <section className="section-padding--bottom section-padding--top project-two">
            <div className="container-fluid">
                <div className="section-title text-center">
                    <p className="section-title__text">Latest Project</p>
                    <h2 className="section-title__title">Check Done IT Project</h2>

                </div>
                {portfolioList && portfolioList.length > 0 &&
                    <OwlCarousel className="thm-owl__carousel" {...projectOptions} >
                    {
                        portfolioList.map((item, key) => (
                        <div key={key} className="item">
                            <div className="project-card-one">
                                <div className="project-card-one__image">
                                    <img src={Server + item.main_image} alt={item.main_image_alt} className='img-fluid' />
                                </div>
                                <div className="project-card-one__content">
                                    <div className="project-card-one__content__inner">
                                        <p className="project-card-one__text">{item.category.category_title}</p>
                                        <h3 className="project-card-one__title"><Link to={"/project-detail/" + item.slug}>{item.title}</Link></h3>
                                        <Link to={"/project-detail/" + item.slug} className="project-card-one__more">
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))
                    }
                    </OwlCarousel>
                }
            </div>
        </section>
    )
}

export default PortfolioList