import React from 'react'
import Breadcumb from '../Breadcumb/Main'
import { Link, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { Server } from '../../Server'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import Modal from './Modal'
import OwlCarousel from 'react-owl-carousel'
import format from 'date-fns/format'
const PortfolioDetail = () => {
	const { slug } = useParams()
	const [singlePortfolio, setSinglePortfolio] = useState(null);
	const [data, setData] = useState([])
	const [spinner, setSpinner] = useState(true);
	const [breadCrumbData, setbreadCrumbData] = useState();
	const [pageMetas, setpageMetas] = useState();

	const logoOptions = {
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		nav: false,
		navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
		dots: true,
		margin: 0,
		items: 4,
		smartSpeed: 700,
		responsive: {
			0: {
				margin: 10,
				items: 1
			},
			375: {
				margin: 10,
				items: 1
			},
			575: {
				margin: 10,
				items:1
			},
			768: {
				margin: 10,
				items: 2
			},
			991: {
				margin: 10,
				items: 3
			},
			1199: {
				margin: 10,
				items: 3
			}
		}
	}

	const getSinglePortfolioDetail = async () => {
		try {
			let url = Server + "/api/getsingleportfolio/" + slug
			let res = await axios.get(url);
			let data = await res.data;
			if (data.success === true) {
				setSinglePortfolio(data.portfolio)
				if (data.portfolio.portfolio_images) {
					setData(data.portfolio.portfolio_images.split(','))
				}
				setpageMetas(data.page_metas)
				setbreadCrumbData(data.breadcrumb_banner)
				setSpinner(false)
			}
			else {
				toast.success(data.message)
			}
		} catch (error) {
			setSpinner(false)
			return toast.error("Unexpected error occurred!")
		}
	}

	useEffect(() => {
		getSinglePortfolioDetail();
	}, [slug])


	const [clickedImg, setClickedImg] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(null);

	const handleClick = (item, index) => {
		setCurrentIndex(index);
		setClickedImg(item);
	};

	const handelRotationRight = () => {
		const totalLength = data.length;
		if (currentIndex + 1 >= totalLength) {
			setCurrentIndex(0);
			const newUrl = data[0];
			setClickedImg(newUrl);
			return;
		}
		const newIndex = currentIndex + 1;
		const newUrl = data.filter((item) => {
			return data.indexOf(item) === newIndex;
		});
		const newItem = newUrl[0];
		setClickedImg(newItem);
		setCurrentIndex(newIndex);
	};

	const handelRotationLeft = () => {
		const totalLength = data.length;
		if (currentIndex === 0) {
			setCurrentIndex(totalLength - 1);
			const newUrl = data[totalLength - 1];
			setClickedImg(newUrl);
			return;
		}
		const newIndex = currentIndex - 1;
		const newUrl = data.filter((item) => {
			return data.indexOf(item) === newIndex;
		});
		const newItem = newUrl[0];
		setClickedImg(newItem);
		setCurrentIndex(newIndex);
	};



	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'SoftTecHub'}</title>
				<meta name='description' content={pageMetas && pageMetas.meta_description} />
				<meta name='keywords' content={pageMetas && pageMetas.meta_keywords} />
			</Helmet>
			<Breadcumb BreadCumbData={breadCrumbData} Title={singlePortfolio && singlePortfolio.title} Breadcumb="PORTFOLIO" />
			<section className=" section-padding--bottom mt-5">
				<div className="container">
					<div className='project-details p-5'>
						<div className='row'>
							<div className='col-md-6'>
								<div className="project-details__image">
									{singlePortfolio &&
										<img src={Server + singlePortfolio.main_image} alt={singlePortfolio && singlePortfolio.main_image_alt} />
									}
								</div>
							</div>
							<div className='col-md-6'>
								<div className="project-details__info">
									<div className='project-details__info__item text-center'>
										<h4 className="project-details__title">{singlePortfolio && singlePortfolio.title}</h4>
									</div>
									<div className="project-details__info__item">
										<h4 className="project-details__info__title mb-0">Client: <span className='className="project-details__info__text ms-2 fw-normal'>{singlePortfolio && singlePortfolio.client}</span></h4>
									</div>

									<div className="project-details__info__item">
										<h4 className="project-details__info__title mb-0">Category: <span className="project-details__info__text ms-2 fw-normal">{singlePortfolio && singlePortfolio.category.category_title}</span></h4>
									</div>
									<div className="project-details__info__item">
										<h4 className="project-details__info__title mb-0">Date: <span className="project-details__info__text ms-2 fw-normal">{format(new Date(singlePortfolio && singlePortfolio.created_at), 'do  MMMM yyyy')}</span></h4>
									</div>
								</div>
							</div>
						</div>
						<div className="project-details__content">
							{singlePortfolio &&
								<div
									dangerouslySetInnerHTML={{ __html: singlePortfolio.portfolio_description }}
								/>
							}
						</div>

						<div className='row'>
							<div className='col-12 mt-2 text-center'>
								<h5 className='mt-5 mb-0'>PORTFOLIO <span className='text-red'>GALLERY</span></h5>
							</div>
						</div>
						<div className="row mt-3">
							{data && data.length>0 &&
								<OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...logoOptions}>
									{data.map((item, index) => (
										<div key={index} className="col-md-3 col-sm-6 portfolio-gallery-image">
											<img
												src={Server + item}
												alt={item}
												onClick={() => handleClick(item, index)}
												className='img-fluid '
											/>
										</div>
									))}

								</OwlCarousel>
							}
							<div>
								{clickedImg && (
									<Modal
										clickedImg={clickedImg}
										handelRotationRight={handelRotationRight}
										setClickedImg={setClickedImg}
										handelRotationLeft={handelRotationLeft}
									/>
								)}
							</div>
						</div>


					</div>
				</div>
			</section>
		</>
	)
}

export default PortfolioDetail