import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import About from '../components/About/Main'
import Contact from '../components/Contact/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import Navbar from '../components/Navbar/Main'
import Dynamic from '../components/Dynamic/Main'
import Page404 from '../components/Page404/Main'
import Blog from '../components/Blog/Blogs/Main'
import BlogDetails from '../components/Blog/BlogDetails/Main'
import Portfolio from '../components/Portfolio/Main'
import PortfolioDetail from '../components/Portfolio/PortfolioDetail'
const Routing = () => {
  const [errorvisible, setErrorvisible] = useState(false);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home setErrorvisible={setErrorvisible} />} />
        <Route path="/about" element={<About setErrorvisible={setErrorvisible} />} />
        <Route path="/contact" element={<Contact setErrorvisible={setErrorvisible} />} />
        <Route path="/blog/:slug" element={<BlogDetails setErrorvisible={setErrorvisible} />} />

        <Route path="/blog" element={<Blog setErrorvisible={setErrorvisible} />} />
        <Route path='/portfolio' element={<Portfolio setErrorvisible={setErrorvisible} />} />
        <Route path='/project-detail/:slug' element={<PortfolioDetail setErrorvisible={setErrorvisible} />} />
        <Route path="/page-404" element={<Page404 />} />
        <Route path="/*" element={<Dynamic setErrorvisible={setErrorvisible} />} />
      </Routes>
      {
        errorvisible === true &&  <section className='py-5'>
        <div className='container py-5 text-center mb-5'>
          <p>Some unexpected error occurred!</p>
        </div>

      </section>
      }
     
      <Footer />
    </>
  )
}

export default Routing