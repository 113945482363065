import React from 'react'
import { useFormik } from 'formik';
import { commentSchema } from '../../../Schemas'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Server } from '../../../Server';
import { format } from 'date-fns';


const initialValues = {
    email: '',
    name: '',
    comment: ''
}

const BlogComments = ({ singleBlog }) => {

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: commentSchema,
        onSubmit: (values, actions) => {
            values['blog_id'] = singleBlog.id
            axios.get('/sanctum/csrf-cookie').then((response) => {
                axios
                    .post('/api/submit-blog-comment', values)
                    .then((res) => {
                        if (res.data.status === false) {
                            if (res.data.message) { toast.error(res.data.message) }

                            if (res.data.errors) {
                                Object.keys(res.data.errors).forEach(key => {
                                    res.data.errors[key].map(item => {
                                        toast.error(item);
                                    })

                                })
                            }
                        } else {
                            toast.success(res.data.message);
                            actions.resetForm();
                        }

                    })
                    .catch((error) => {
                        toast.error("Unexpected error occurred!");
                    })
            })
        },
    })

    return (
        <>
            <div className="comments-one">
                <h3 className="comments-one__title">{(singleBlog && singleBlog.blog_comments) ? singleBlog.blog_comments.length : 0} Comments</h3>
                {singleBlog && singleBlog.blog_comments && singleBlog.blog_comments.length > 0 &&
                    singleBlog.blog_comments.map((item, key) => (
                        <div key={key} className="comments-one__item">
                            <div className="comments-one__item__content">
                                <h3 className="comments-one__item__title">{item.name}</h3>

                                <p className="comments-one__item__text">{item.comment}</p>

                                <p className="comments-one__item__btn">Posted on: <span> {format(new Date(item.created_at), 'do  MMMM yyyy')}</span></p>
                            </div>
                        </div>
                    ))
                }

            </div>

            <div className="comment-form">
                <h3 className="comment-form__title">Leave a Comment</h3>
                <form
                    id="commentForm"
                    onSubmit={handleSubmit}
                    method='post'
                    className="contact-one__form contact-form-validated comment-form__form"
                >
                    <div className="row ">
                        <div className="col-lg-6 col-md-12">
                            <input
                                name="name"
                                id="name"
                                type="text"
                                placeholder="Name"
                                value={values.name}
                                onBlur={handleBlur} onChange={handleChange} />
                            <small className='text-danger fw-bold'>{errors.name && touched.name && errors.name}</small>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <input
                                name="email"
                                id="email"
                                type="email"
                                placeholder="Email"
                                value={values.email} onBlur={handleBlur} onChange={handleChange} />
                            <small className='text-danger fw-bold'>{errors.email && touched.email && errors.email}</small>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <textarea
                                name="comment"
                                id="comment"
                                cols="30"
                                rows="9"
                                value={values.comment} onBlur={handleBlur} onChange={handleChange}
                                placeholder="Write message">
                            </textarea>
                            <small className='text-danger fw-bold'>{errors.comment && touched.comment && errors.comment}</small>
                        </div>
                        <div className="col-md-12">
                            <button className="thm-btn contact-one__btn py-1" type="submit"><span>Submit</span></button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}

export default BlogComments