import * as Yup from 'yup'

export const inquirySchema = Yup.object({
  email: Yup.string()
    .email('Please enter valid email address!')
    .required('Please enter your email!'),
    customer_name: Yup.string('Name must be a string!')
    .required('Please enter your name!'),
    customer_mobile: Yup.string()
    .required('Please enter your phone number!'),
    email_subject: Yup.string()
    .required('Please enter the subject!'),
    email_message: Yup.string()
    .required('Please enter your query!'),
})

export const commentSchema = Yup.object({
  email: Yup.string().email().max(50).required("Email is required"),
  name : Yup.string().min(2, 'Name must contain at least 2 characters').max(30, 'Name must not exceed 30 characters').required("Please enter your name!"),
  comment: Yup.string().min(10, 'Comment must contain at least 10 characters').max(300, 'Comment must not exceed 300 characters').required("Please enter your comment!"),
})