import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import BGImg3 from '../../assets/images/shapes/service-four-bg-1-1.png'
import BGImg6 from '../../assets/images/shapes/team-home-bg-1-1.jpg'
import BGImg7 from '../../assets/images/background/ebedef02b15ad62cf79a964a43d16b8e.png'
import { format } from 'date-fns';
import AnimatedNumber from "animated-number-react"
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Server } from '../../Server'
import Star from './Star'
import { toast } from 'react-toastify'
import Spinner from '../Spinner/Main'
import PortfolioList from '../Portfolio/PortfolioList'
const Home = ({ setErrorvisible }) => {
    const [ytShow, setytShow] = useState(false)
    const [homeslider, sethomeslider] = useState([]);
    const [pageMetas, setpageMetas] = useState('');
    const [aboutCompanyLeft, setaboutCompanyLeft] = useState('');
    const [aboutCompanyRight, setaboutCompanyRight] = useState('');
    const [funFactSection, setFunFactSection] = useState('')
    const [reviews, setReviews] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [portfolioList, setPortfolioList] = useState([]);
    const [spinner, setSpinner] = useState(true);
 
    const logoOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span className=\"fa-solid fa-angle-left\"></span>", "<span className=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 30,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 30,
                items: 1
            },
            375: {
                margin: 30,
                items: 1
            },
            575: {
                margin: 30,
                items: 1
            },
            767: {
                margin: 50,
                items: 1
            },
            991: {
                margin: 40,
                items: 1
            },
            1199: {
                margin: 80,
                items: 1
            }
        }
    }
    const getHomeDetails = async () => {
        await axios
            .get('/api/home-page-details')
            .then((res) => {
                sethomeslider(res.data.home_slider)
                setpageMetas(res.data.page_metas)
                setaboutCompanyLeft(res.data.about_company_left)
                setaboutCompanyRight(res.data.about_company_right)
                setFunFactSection(res.data.fun_fact_section)
                setReviews(res.data.reviews_list)
                setBlogs(res.data.blogs)
                setPortfolioList(res.data.portfolio_list)
                setSpinner(false)
            })
            .catch((error) => {
                setSpinner(false)
                toast.error('Some error occurred!')
                setErrorvisible(true)
            })
    }

    useEffect(() => {
        setErrorvisible(false)
        getHomeDetails()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'SoftTecHub'}</title>
            </Helmet>
            {spinner && <Spinner />}
            {homeslider &&
                <>
                    {/* Banner Section Start */}
                    <div className="slider-one wow fadeIn animated">
                        <section className="hero-it-solution" style={{ backgroundImage: `url(${BGImg6})` }} >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-12">
                                        <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                            <h1 className="fw-bold display-6">We Care Your any <span className='text-red'>IT Solution</span></h1>
                                            <p className="lead">
                                                We focus on professional and best user experience designs to provide high level quality satisfaction to our customers
                                            </p>
                                            <div className="action-btn mt-5 mb-4  align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                                                <Link to='/contact' className="thm-btn slider-one__btn"><span>Get a Qoute</span></Link>
                                                <Link to='/about' className="thm-btn home-banner-outline-btn"><span>Discover More</span></Link>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="position-relative aos-init text-center aos-animate" data-aos="fade-up" data-aos-delay="200">
                                            <img src={BGImg7} alt="dashboard" style={{ borderRadius: '12px' }} className="img-fluid position-relative rounded-custom mt-lg-5" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    {/* Banner Section End */}

                    {/* About Section Start */}
                    {aboutCompanyLeft &&
                        <section className="section-padding--bottom section-padding--top about-five" >
                            <div className="container">
                                <div className="row gutter-y-60">
                                    <div className="col-lg-6">
                                        <div className="about-five__images">
                                            <div className="about-five__images__shape"></div>
                                            {aboutCompanyLeft && aboutCompanyLeft.image &&
                                                <img src={`${Server}` + aboutCompanyLeft.image} className="wow fadeInUp"
                                                    data-wow-duration="1500ms" alt="" />
                                            }
                                            {aboutCompanyLeft && aboutCompanyLeft.image &&
                                                <img src={`${Server}` + aboutCompanyLeft.content} alt="" style={{ border: '2px solid #000' }} />
                                            }

                                            <div className="about-five__video">
                                                <Link to="#" onClick={() => setytShow(true)} className="video-popup">
                                                    <i className="fa fa-play"></i>
                                                </Link>
                                            </div>
                                            <div className="about-five__images__caption count-box wow fadeInUp" data-wow-duration="1500ms">
                                                <span className="count-text"><AnimatedNumber value={new Date().getFullYear() - aboutCompanyLeft.col ? new Date().getFullYear() - aboutCompanyLeft.col : 10} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                                Years <br />
                                                Experience
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about-five__content">
                                            <div className="section-title ">
                                                <p className="section-title__text">{aboutCompanyRight.content}</p>
                                                <h2 className="section-title__title">{aboutCompanyRight.main_heading}</h2>

                                            </div>
                                            <div className="about-five__text">
                                                <div dangerouslySetInnerHTML={{ __html: aboutCompanyRight.content_extra }} />
                                                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                                    <li className="py-1">
                                                        <div className="d-flex about-icon-box align-items-center">
                                                            <div className="me-3">
                                                                <img src="../../assets/images/shapes/team.png" alt="" />
                                                            </div>
                                                            <div>
                                                                <h5>Expert around the world</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="py-1">
                                                        <div className="d-flex about-icon-box align-items-center">
                                                            <div className="me-3">
                                                                <img src="../../assets/images/shapes/pie.png" alt="" />
                                                            </div>
                                                            <div>
                                                                <h5>Best Practice For Business</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                                    <li className="py-1">
                                                        <i className="fas fa-check-circle me-2 text-red"></i>Web Development
                                                    </li>
                                                    <li className="py-1">
                                                        <i className="fas fa-check-circle me-2 text-red"></i>Mobile Apps Development
                                                    </li>
                                                    <li className="py-1">
                                                        <i className="fas fa-check-circle me-2 text-red"></i>Theme Design
                                                    </li>
                                                    <li className="py-1">
                                                        <i className="fas fa-check-circle me-2 text-red"></i>Digital Marketing
                                                    </li>
                                                    <li className="py-1">
                                                        <i className="fas fa-check-circle me-2 text-red"></i>Business Management
                                                    </li>
                                                    <li className="py-1">
                                                        <i className="fas fa-check-circle me-2 text-red"></i>Virtual Assistant
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    }
                    {/* About Section End */}

                    {/* Services Start */}
                    <section
                        className="service-four gray-bg section-padding--top pb-5 background-repeat-no background-position-top-right"
                        style={{ backgroundImage: `url(${BGImg3})` }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-10">
                                    <div className="section-heading text-center">
                                        <h2 className='fw-bold text-black'>Services We Provide</h2>
                                        <p>
                                            Solutions for small business owners and entrepreneurs on growth strategies,
                                            how to grow your business by entering global markets, and sales growth strategies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 p-0">
                                    <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end">
                                        <div className="service-icon icon-center">
                                            <img src="../../assets/images/shapes/graphic-design.png" alt="service icon" width="65" height="65" />
                                        </div>
                                        <div className="service-info-wrap">
                                            <h3 className="h5">Web Development</h3>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 p-0">
                                    <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end">
                                        <div className="service-icon icon-center">
                                            <img src="../../assets/images/shapes/graphic-design.png" alt="service icon" width="65" height="65" />
                                        </div>
                                        <div className="service-info-wrap">
                                            <h3 className="h5">Content Writing</h3>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 p-0">
                                    <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom">
                                        <div className="service-icon icon-center">
                                            <img src="../../assets/images/shapes/graphic-design.png" alt="service icon" width="65" height="65" />
                                        </div>
                                        <div className="service-info-wrap">
                                            <h3 className="h5">Search Engine Optimization</h3>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 p-0">
                                    <div className="single-service p-lg-5 p-4 text-center border-end">
                                        <div className="service-icon icon-center">
                                            <img src="../../assets/images/shapes/graphic-design.png" alt="service icon" width="65" height="65" />
                                        </div>
                                        <div className="feature-info-wrap">
                                            <h3 className="h5">Social Media Management</h3>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 p-0">
                                    <div className="single-service p-lg-5 p-4 text-center border-end">
                                        <div className="service-icon icon-center">
                                            <img src="../../assets/images/shapes/graphic-design.png" alt="service icon" width="65" height="65" />
                                        </div>
                                        <div className="feature-info-wrap">
                                            <h3 className="h5">Graphic Designing</h3>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 p-0">
                                    <div className="single-service p-lg-5 p-4 text-center">
                                        <div className="service-icon icon-center">
                                            <img src="../../assets/images/shapes/graphic-design.png" alt="service icon" width="65" height="65" />
                                        </div>
                                        <div className="feature-info-wrap">
                                            <h3 className="h5">2D Animation</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Services End */}

                    {/* Portfolio Section Start */}
                    {portfolioList &&
                        <PortfolioList portfolioList={portfolioList} />
                    }
                    {/* Portfolio Section End */}

                    {/* FunFact Start*/}
                    <section className=" mt-5 bg-light py-5 py-xl-8">
                        <div className="container">
                            <div className="row gy-5 gy-lg-0 gx-lg-8 align-items-center">
                                {/* <div className="col-12 col-lg-5">
                                    <h2 className="mb-3 mb-xl-4">You convey the <span className='text-red fw-bold'>Idea</span> <br /> We deliver a refined <span className='text-red fw-bold'>interface</span>.</h2>
                                    <p className="mb-4 mb-xl-5">We're one of the finest web design agencies that team up with startups, agencies, and founders to design digital products and websites.</p>
                                    <Link to='/portfolio' className="thm-btn slider-one__btn"><span>More Details</span></Link>
                                </div> */}
                                <div className="col-12">
                                    <div className="row gy-4">
                                        <div className="col-lg-3 col-6">
                                            <div className="card border-0 border-bottom border-bottom-red shadow-sm">
                                                <div className="card-body text-center p-4 p-xxl-5">
                                                    <div className="btn bg-red btn-circle btn-circle-4xl pe-none mb-2 funfact-icon">
                                                        <i className="fas fa-laptop-house fs-4 text-white mt-3"></i>
                                                    </div>
                                                    <h3 className="h2 mt-2 mb-0">{new Date().getFullYear() - funFactSection.main_heading ? new Date().getFullYear() - funFactSection.main_heading : 12}</h3>
                                                    <p className="fs-6 mb-0 text-secondary">Years of Experience</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="card border-0 border-bottom border-bottom-red shadow-sm">
                                                <div className="card-body text-center p-4 p-xxl-5">
                                                    <div className="btn bg-red btn-circle btn-circle-4xl pe-none mb-2 funfact-icon">
                                                        <i className="fas fa-shield-alt fs-4 text-white mt-3"></i>
                                                    </div>
                                                    <h3 className="h2 mt-2 mb-0">{funFactSection && funFactSection.content}</h3>
                                                    <p className="fs-6 mb-0 text-secondary">Finished Projects</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="card border-0 border-bottom border-bottom-red shadow-sm">
                                                <div className="card-body text-center p-4 p-xxl-5">
                                                    <div className="btn bg-red btn-circle btn-circle-4xl pe-none mb-2 funfact-icon">
                                                        <i className="fas fa-users fs-4 text-white mt-3"></i>
                                                    </div>
                                                    <h3 className="h2 mt-2 mb-0">{funFactSection && funFactSection.col}</h3>
                                                    <p className="fs-6 mb-0 text-secondary">Professional Experts</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="card border-0 border-bottom border-bottom-red shadow-sm">
                                                <div className="card-body text-center p-4 p-xxl-5">
                                                    <div className="btn bg-red btn-circle btn-circle-4xl pe-none mb-2 funfact-icon">
                                                        <i className="far fa-smile fs-4 text-white mt-3"></i>
                                                    </div>
                                                    <h3 className="h2 mt-2 mb-0">{funFactSection && funFactSection.content_extra}</h3>
                                                    <p className="fs-6 mb-0 text-secondary">Satisfied Clients</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* FunFact End */}

                    {/* Blogs Start */}
                    <section className="section-padding--bottom  section-padding--top" >
                        <div className="container">
                            <div className="section-title text-center">
                                <p className="section-title__text text-red">Blog Posts</p>
                                <h4 className="section-title__title">Checkout Our Latest News & Articles</h4>
                            </div>
                            <div className="row gutter-y-30">
                                {blogs && blogs.length > 0 &&
                                    blogs.map((item, key) => (
                                        <div key={key} className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                                            data-wow-delay="000ms">
                                            <div className="blog-card-one">
                                                <div className="blog-card-one__image">
                                                    <img src={Server + item.featured_image} alt={item.featured_image_alt} />
                                                    <Link to={"/blog/" + item.slug}></Link>
                                                </div>
                                                <div className="blog-card-one__content">
                                                    <div className="blog-card-one__meta">
                                                        <div className="blog-card-one__date">
                                                            <i className="fa fa-calendar-alt" aria-hidden="true"></i>
                                                            {format(new Date(item.created_at), 'do  MMMM yyyy')}
                                                        </div>
                                                        {/* <Link to="/blog" className="blog-card-one__category">{item.blog_category.category_title}</Link> */}
                                                    </div>
                                                    <h3 className="blog-card-one__title"> <Link to={"/blog/" + item.slug}>{item.title}</Link></h3>
                                                    <Link to={"/blog/" + item.slug} className="blog-card-one__more">
                                                        Read More
                                                        <i className="fa fa-arrow-right"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                    {/* Blogs End*/}

                    {/* testimonial Start */}
                    <section
                        className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center ">
                        <div className="container">
                            <div className="row gutter-y-60">
                                <div className="col-lg-5">
                                    <div className="testimonials-two__content">
                                        <div className='row'>
                                            <div className='col-lg-1 col-12'>
                                                <div className="section-title pb-0">
                                                    <p className="section-title__text text-vertical text-primary">See What Our Clients Say</p>
                                                </div>
                                            </div>
                                            <div className='col-lg-11 col-12'>
                                                <div className="section-title mt-lg-5">
                                                    <h2 className="section-title__title">We Are Trusted
                                                        Worldwide People</h2>
                                                </div>
                                                <div className="testimonials-two__content__text">As an IT Company in Switzerland we dedicated our team of professionals to the highest standards of technical expertise and client care.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="testimonials-two__items">
                                        <div className="row gutter-y-30">
                                            {reviews && reviews.length > 0 &&
                                                <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...logoOptions}>
                                                    {reviews.map((item4, index4) => (
                                                        <div key={index4} className="col-lg-12">
                                                            <div className="testimonials-one-card">
                                                                <div className="testimonials-one-card__image">
                                                                    <img src="assets/images/resources/testimonial.jpg" alt="" />
                                                                </div>
                                                                <div className="testimonials-one-card__content">
                                                                    <div className="testimonials-one-card__text">
                                                                        {item4.message}
                                                                    </div>
                                                                    <h3 className="testimonials-one-card__title">{item4.name}</h3>
                                                                    <p className="testimonials-one-card__designation">
                                                                        <Star stars={item4.rating} />
                                                                    </p>
                                                                    <i className="icon-right-quote testimonials-one-card__icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </OwlCarousel>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* testimonial end */}
                </>
            }

            {ytShow &&
                <div className="YouTubePopUp-Wrap">
                    <div className="YouTubePopUp-Content">
                        <span className="YouTubePopUp-Close" onClick={() => setytShow(false)}></span>
                        {aboutCompanyLeft &&
                            <iframe src={`${Server}` + aboutCompanyLeft.content_extra} title='ytvideo' allowFullScreen></iframe>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Home