import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';

const Main = () => {
    const [servicesList, setServicesList] = useState([]);

    const getServicesList = () => {
        axios
            .get('/api/services-list')
            .then((res) => {
                setServicesList(res.data.services_list)
            })
            .catch((error) => {
             
            })
    }

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [phoneno, setphoneno] = useState('');
    const [subject, setsubject] = useState('');
    const [selectedservice, setselectedservice] = useState('');
    const [message, setmessage] = useState('');
    const [fileSelected, setfileSelected] = useState(null);
    
    const handleFileSelect = (event) => {
        setfileSelected(event.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        axios.get('/sanctum/csrf-cookie').then((response) => {
            axios
                .post('/api/customer-consultation', { 'name': name, 'email': email, 'phoneno': phoneno, 'subject': subject, 'selected_service': selectedservice, 'message': message, 'file' : fileSelected }, {headers: { "Content-Type": "multipart/form-data" }})
                .then((res) => {
                    if (res.data.success === true && res.status === 200) {
                        toast.success("Thank you. We'll get back to you soon! ")
                        setname('')
                        setemail('')
                        setphoneno('')
                        setsubject('')
                        setselectedservice('')
                        setmessage('')
                        setfileSelected(null)
                    }
                    else if (res.status === 200 && res.data.success === false) {
                        if (res.data.data) {
                            Object.keys(res.data.data).forEach(key => {
                                res.data.data[key].map(item => (
                                    toast.error(item)
                                ))
                            })
                        }
                    }
                })
                .catch((error) => {
                    toast.error('Some error occurred');
                    toast.error('Please try again!')
                })
        })
    }

    useEffect(() => {
        getServicesList()
    }, [])
    return (
        <div data-backdrop="false" className="modal modal-lg" style={{ border: 'none' }} id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" style={{ backgroundColor: 'transparent' }}>
                <div className="modal-content" style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body">
                        <div className="sidebar__item sidebar__item--cta" style={{ marginTop: '0px !important' }}>
                            <div className='consultation-form'>
                                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark"></i></button>
                                <h3 style={{ marginBottom: '0px', textAlign: 'center', color: 'white' }}> Get Consultation </h3>
                                <form onSubmit={handleSubmit} className=" mt-0" style={{ padding: '0px !important' }} encType='multipart/form-data'>
                                    <div className='row'>
                                        <div className="section-title" style={{ padding: '0px', paddingTop: '10px' }}>
                                            <p className="section-title__text text-center fs-6">Fill the form below</p>
                                        </div>
                                        <div className="col-sm-6 col-12 mt-2">
                                            <input type="text" placeholder="Your name" value={name} onChange={(e) => setname(e.target.value)} name="name" />
                                        </div>
                                        <div className="col-sm-6 col-12 mt-2">
                                            <input type="text" placeholder="Your Email" value={email} onChange={(e) => setemail(e.target.value)} name="email" />
                                        </div>
                                        <div className="col-sm-6 col-12 mt-2">
                                            <input type="text" placeholder="Your Phone No" value={phoneno} onChange={(e) => setphoneno(e.target.value)} name="phone_no" />
                                        </div>
                                        <div className="col-sm-6 col-12 mt-2">
                                            <input type="text" placeholder="Subject" value={subject} onChange={(e) => setsubject(e.target.value)} name="subject" />
                                        </div>
                                        <div className="col-12 mt-2">
                                            <select name='service_selected' value={selectedservice} onChange={(e) => setselectedservice(e.target.value)}>
                                                <option value=''>Select Service</option>
                                                {servicesList && servicesList.map((list_item, key) => (
                                                    <option key={key} value={list_item.title} className='option-box'>{list_item.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <textarea value={message} onChange={(e) => setmessage(e.target.value)} name='message' placeholder='Type your message here...'>
                                            </textarea>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <label className='text-white'>Attachment? <span style={{ color: '#ff0911' }}>(Optional)</span></label>
                                            <input type="file" className='pt-0' onChange={handleFileSelect} name="file" />
                                        </div>
                                        <div className='col-12 mt-1 text-center'>
                                            <button className="thm-btn contact-one__btn mb-2" style={{ padding: "10px 20px", margin: '0px auto' }} type="submit"><span>send a message</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main