import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import BlogSidebar from '../BlogSidebar/Main'
import axios from 'axios'
import Spinner from '../../Spinner/Main'
import { Server } from '../../../Server'
import { toast } from 'react-toastify'
import BlogList from './BlogList'
import Pagination from "react-js-pagination";
import { Helmet } from 'react-helmet'


const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [blogCategories, setBlogCategories] = useState([]);
    const [trending, setTrending] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [breadCrumbData, setbreadCrumbData] = useState();
    const [pageMetas, setpageMetas] = useState();

    const getBlogs = async (pageNumber = 1) => {
        try {
            let url = Server + `/api/getBlogsList?page=${pageNumber}`
            let res = await axios.get(url);
            let data = await res.data;
            if (data.success === true) {
                setBlogs(data.data.data)
                setFilterData(data.data)
                setBlogCategories(data.blog_categories)
                setTrending(data.trending)
                setpageMetas(res.data.page_metas)
                setbreadCrumbData(res.data.breadcrumb_banner)
                setSpinner(false)
            } else {
                toast.success(data.message)
                setSpinner(false)
            }

        } catch (error) {
            setSpinner(false)
            return toast.error("Unexpected error occurred!")
        }
    }
    useEffect(() => {
        getBlogs();

    }, [])

    const categoryFilter = async(name) => {
        if (name === 'All') {
            getBlogs()
        } else {
            try {
                let url = Server + `/api/getBlogsList?type=search&category=`+name
                let res = await axios.get(url);
                let data = await res.data;
                console.log(data);
                if (data.success === true) {
                    setBlogs(data.data.data)
                    setFilterData(data.data)
                    setBlogCategories(data.blog_categories)
                } else {
                    toast.success(data.message)
                }
            } catch (error) {
                return toast.error("Unexpected error occurred!")
            }
        }
    }

    const blogSearch = async(e) => {
        e.preventDefault();
        let blog_search = document.getElementById("blog_search").value;
        try {
            let url = Server + `/api/getBlogsList?type=search&search=`+blog_search
            let res = await axios.get(url);
            let data = await res.data;
            console.log(data);

            if (data.success === true) {
                setBlogs(data.data.data)
                setFilterData(data.data)
                setBlogCategories(data.blog_categories)
            } else {
                toast.success(data.message)
                setSpinner(false)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error("Unexpected error occurred!")
        }
    }


    const clearBlogSearc = () => {
        getBlogs()
        document.getElementById("blog_search").value = null;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'SoftTecHub'}</title>
                <meta name='description' content={pageMetas && pageMetas.meta_description} />
                <meta name='keywords' content={pageMetas && pageMetas.meta_keywords} />
            </Helmet>
            {spinner ? <Spinner /> :
                <>
                    <Breadcumb BreadCumbData={breadCrumbData && breadCrumbData} Title='Blogs' Breadcumb="BLOGS" />
                    <section className="section-padding--top section-padding--bottom blogs">
                        <div className="container">
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <div className="row">
                                        <BlogList filterData={filterData.data} />
                                    </div>
                                    <div className='row'>
                                        <div className="col-12 mt-5">
                                            <div className="pagination-area mb-sm-5 mb-lg-0">
                                                <nav aria-label="Page navigation example">
                                                    <Pagination
                                                        totalItemsCount={filterData && filterData.total}
                                                        activePage={filterData && filterData.current_page}
                                                        itemsCountPerPage={filterData && filterData.per_page}
                                                        onChange={(pageNumber) => { getBlogs(pageNumber) }}
                                                        itemClass='page-item'
                                                        linkClass='page-link'
                                                        activeClass='active'
                                                    />
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <BlogSidebar blogCategories={blogCategories} trending={trending} categoryFilter={categoryFilter} blogCount={blogs.length} blogSearch={blogSearch} clearBlogSearc={clearBlogSearc} />
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }

        </>
    )
}

export default Blogs