import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import { useState } from 'react'
import { useEffect } from 'react'
import { Server } from '../../../Server'
import axios from 'axios'
import { toast } from 'react-toastify'
import SingleBlog from './SingleBlog'
import BlogComments from './BlogComments'
import { Helmet } from 'react-helmet'
import Spinner from '../../Spinner/Main'

const BlogDetails = () => {
    const { slug } = useParams()
    const [singleBlog, setSingleBlog] = useState(null);
    const [spinner, setSpinner] = useState(true)
    const [breadCrumbData, setbreadCrumbData] = useState();
    const [pageMetas, setpageMetas] = useState();

    const getSingleBlog = async () => {
        try {
            let url = Server + "/api/getblogdetail/" + slug
            let res = await axios.get(url);
            let data = await res.data;
            if (data.success === true) {
                setSingleBlog(data.blog)
                setpageMetas(data.page_metas)
                setbreadCrumbData(data.breadcrumb_banner)
                setSpinner(false)
            }
        } catch (error) {
            setSpinner(false)
            return toast.error("Unexpected error occurred!")
        }
    }

    useEffect(() => {
        getSingleBlog();
    }, [slug])


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'SoftTecHub'}</title>
                <meta name='description' content={pageMetas && pageMetas.meta_description} />
                <meta name='keywords' content={pageMetas && pageMetas.meta_keywords} />
            </Helmet>
            {spinner ? <Spinner /> :
                <>
                    <Breadcumb BreadCumbData={breadCrumbData} Title={singleBlog && singleBlog.title} Breadcumb="BLOG" />
                    <section className="section-padding--bottom ">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">

                                    <SingleBlog singleBlog={singleBlog} />

                                    {/* Blog Comments */}
                                    <BlogComments singleBlog={singleBlog} />
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default BlogDetails